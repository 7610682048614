<template>
    <v-app id="inspire">
        <NavBar></NavBar>
        <v-main>
            <v-container fluid id="dashboard-vue">
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-footer style="background-color: #265b93" color="darken-3" app>
            <span class="white--text">&copy; {{todayDate}}</span>
        </v-footer>
    </v-app>
</template>

<script>
    import NavBar from '../components/NavBar'
    import store from "../store";
    import {mapGetters} from 'vuex'

    export default {
        components: {
            NavBar,
            ...mapGetters(['getDashboardLink'])
        },
        data() {
            return {
                drawer: null,
                todayDate:""
            }
        },
        computed: {},
        created() {
            this.getMenuData()
            this.getNow()
        },
        methods: {
            async getMenuData() {
                await store.dispatch('userData', localStorage.getItem('userInfo'))
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear()
                this.todayDate = date;
            }
        }

    }
</script>

<style lang="scss" scoped>
    #dashboard-vue {
        ::v-deep .v-main {
            padding: 0px 0px 36px 5rem !important;
        }
    }
    
</style>