<template>
    <div>
        <v-navigation-drawer v-model="drawer"
                             :clipped="$vuetify.breakpoint.lgAndUp"
                             :mini-variant.sync="mini"
                             dark
                             color="#265b93"
                             permanent
                             width="260"
                             app>
            <v-list dense>
                <v-list-item >
                    <v-list-item-action>
                        <v-avatar size="36">
                            <v-icon dark>account_circle</v-icon>
                        </v-avatar>

                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{user.name}}</v-list-item-title>
                    </v-list-item-content>
                    <v-btn icon
                           @click.stop="mini = !mini">
                        <v-icon>
                            mdi-format-list-bulleted
                        </v-icon>
                    </v-btn>
                </v-list-item>
                <v-list-item v-for="(menu,count) in menuListMenu"
                             :key="'menu'+count" :to="menu.link" link>
                    <v-list-item-action>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ menu.menuName }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-action>
                        <v-icon>power_settings_new</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('logout')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <!--
            <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-1" dark>

            </v-app-bar>-->
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp"
                   app style="background-color: #265b93" color="darken-3" dark
                   class="pb-2">
            <!-- <v-app-bar-nav-icon>Menu</v-app-bar-nav-icon>-->
            <router-link to="/">
                <v-avatar color="white" size="50" width="55px" class="pa-1">
                    <img src="@/assets/logo.png"
                         alt="John">

                </v-avatar>
            </router-link>
            <v-spacer></v-spacer>
            <v-toolbar-title class="pl-4">
                {{$t('moctca_project_detail.project_name')}} (CeTMIS)
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <Language />

        </v-app-bar>

    </div>
</template>

<script>
    import store from "../store";
    import Language from "./Language";

    export default {
        name: "NavBar",
        props: ["userInfo"],
        components: { Language },
        data() {
            return {
                menuListMenu:[],
                dashboard: false,
                dialog: false,
                drawer: false,
                navRole: "",
                user: {},
                items: [],
                dashboardLink: "/",
                breadCrumb: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        to: "/"
                    },
                    {
                        text: "Link 1",
                        disabled: false,
                        href: "breadcrumbs_link_1"
                    },
                    {
                        text: "Link 2",
                        disabled: true,
                        href: "breadcrumbs_link_2"
                    }
                ],
                mini: false,
            }
        },
        created() {
            this.getMenu();
        },

        watch: {
            '$store.state.lang': function () {
                if (this.$store.state.lang === "नेपाली") {
                    this.menuListMenu= this.items[0].menuList.map((item) => {
                        return {
                            menuName: item.nepali,
                            link: item.link,
                            icon: item.icon
                        }
                    })
                }
                else {
                    this.menuListMenu =  this.items[0].menuList.map((item) => {
                        return {
                            menuName: item.menuName,
                            link: item.link,
                            icon: item.icon
                        }
                    })
                }
            }
        },
        methods: {
            logout() {
                this.$store.dispatch("logout").then(() => {
                    this.$router.push("/homepage");
                });
            },
            getMenu() {
                let data = JSON.parse(store.getters.getInfo);
                this.user.name = data.userName;
                this.dashboardLink = this.$store.getters.getDashboardLink ? this.$store.getters.getDashboardLink : ""
                this.items = data.menuList;
                if (localStorage.getItem('lang') === "ne") {
                    this.menuListMenu = this.items[0].menuList.map((item) => {
                        return {
                            menuName: item.nepali,
                            link: item.link,
                            icon: item.icon
                        }
                    })
                }
                else {
                    this.menuListMenu = this.items[0].menuList.map((item) => {
                        return {
                            menuName: item.menuName,
                            link: item.link,
                            icon: item.icon
                        }
                    })
                }
            },
        }
    };
</script>
<style lang="scss">
    .grid-container {
        display: grid;
        grid-template-columns: 1fr auto;
        color: #fff;
        justify-content: center;
        align-items: center;
        grid-gap: 2px;
    }

    .bread {
        margin-top: 4em;
    }

    .v-breadcrumbs {
        background-color: #f9f7f72e !important;
    }

    .v-avatar {
        margin-left: -0.4em;

        .v-icon {
            color: #1e88e5;
            font-size: 2.5em;
        }
    }
</style>